var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        {
          ref: "basicInfoForm",
          attrs: {
            model: _vm.info,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "表名称", prop: "tableName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入仓库名称" },
                        model: {
                          value: _vm.info.tableName,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "tableName", $$v)
                          },
                          expression: "info.tableName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "表描述", prop: "tableComment" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入仓库名称" },
                        model: {
                          value: _vm.info.tableComment,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "tableComment", $$v)
                          },
                          expression: "info.tableComment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "实体类名称", prop: "className" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入仓库名称" },
                        model: {
                          value: _vm.info.className,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "className", $$v)
                          },
                          expression: "info.className",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "作者", prop: "functionAuthor" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入仓库名称" },
                        model: {
                          value: _vm.info.functionAuthor,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "functionAuthor", $$v)
                          },
                          expression: "info.functionAuthor",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, pull: 3 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: "textarea with clear icon",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.info.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "remark", $$v)
                          },
                          expression: "info.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }